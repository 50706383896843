<template>
    <div
        class="overflow-y-auto mx-0 px-0 py-0 mt-3"
        :style="`height:${this.height}px`"
    >
        <v-row no-gutters class="mx-4">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filters"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :height="'75vh'"
                    fixed-header
                >
                    <template v-slot:top>
                        <div class="top">
                            <v-row
                                no-gutter
                                class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                                style="background-color: #eeeeee"
                            >
                                <h2 class="ml-4 mr-4">
                                    ENGINEERING
                                </h2>
                            </v-row>
                        </div>
                    </template>
                    <!--HEADERS-->
                    <template v-slot:[`header.quote.name`]="{ header }">
                        <v-combobox
                            v-model="quotesToFilter"
                            :items="quotesForUser"
                            :label="header.text"
                            flat
                            solo
                            background-color="#eeeeee"
                            multiple
                            item-text="name"
                            item-value="id"
                            hide-details
                        >
                            <template v-slot:selection="{ index }">
                                <div v-if="index === 0" class="mr-6">
                                    <span>Quotes</span>
                                </div>
                                <v-badge
                                    v-if="index === 0"
                                    :content="quotesToFilter.length"
                                    :value="quotes"
                                    color="primary"
                                    overlap
                                    class="mt-n2"
                                >
                                </v-badge>
                            </template>
                        </v-combobox>
                    </template>
                    <template v-slot:[`header.reference`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="referenceToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.category`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="categoryToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.promiseDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="promiseDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.clientId`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="clientToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.userId`]="{ header }">
                        <v-combobox
                            v-model="usersToFilter"
                            :items="quotesUsers"
                            :label="header.text"
                            flat
                            solo
                            multiple
                            item-text="name"
                            item-value="id"
                            :return-object="false"
                            hide-details
                            background-color="#eeeeee"
                        >
                            <template v-slot:selection="{ index }">
                                <div v-if="index === 0" class="mr-6">
                                    <span>Users</span>
                                </div>
                                <v-badge
                                    v-if="index === 0"
                                    :content="usersToFilter.length"
                                    :value="quotes"
                                    color="primary"
                                    overlap
                                    class="mt-n2"
                                >
                                </v-badge>
                            </template>
                        </v-combobox>
                    </template>
                    <template v-slot:[`header.quote.status`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="statusToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.requestDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="requestDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>

                    <template
                        v-slot:[`header.quote.invitationDate`]="{ header }"
                    >
                        <v-text-field
                            :label="header.text"
                            v-model="invitationDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.engineeringDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="engineeringDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>

                    <!-- Items -->
                    <template v-slot:[`item.index`]="{ index }">
                        <div dense class="pt-2">
                            {{ index + 1 }}
                        </div>
                    </template>
                    <template v-slot:[`item.reference`]="{ item }">
                        <div dense class="pt-2">
                            {{ item.reference }}
                        </div>
                    </template>

                    <template v-slot:[`item.quote.clientId`]="{ item }">
                        <div class="my-4">
                            {{ getClientName(item.quote.clientId) }}
                        </div>
                    </template>

                    <template v-slot:[`item.quote.userId`]="{ item }">
                        <div class="my-0">
                            <div>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            class="my-0 font-weight-medium"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{
                                                item.quote.estimatorInfo
                                                    ? item.quote.estimatorInfo
                                                          .name
                                                    : ''
                                            }}
                                        </p>
                                    </template>
                                    <span>Account M.</span>
                                </v-tooltip>
                            </div>
                            <div>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            class="my-0 font-weight-medium"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{
                                                item.quote.mainContact
                                                    ? item.quote.mainContact
                                                          .name
                                                    : ''
                                            }}
                                        </p>
                                    </template>
                                    <span>Estimator</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.quote.requestDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.quote.requestDate &&
                                item.quote.requestDate._seconds
                                    ? formatDate(
                                          item.quote.requestDate._seconds
                                      )
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.promiseDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.quote.promiseDate &&
                                item.quote.promiseDate._seconds
                                    ? formatDate(
                                          item.quote.promiseDate._seconds
                                      )
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.invitationDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.quote.invitationDate &&
                                item.quote.invitationDate._seconds
                                    ? formatDate(
                                          item.quote.invitationDate._seconds
                                      )
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.engineeringDate`]="{ item }">
                        <div
                            dense
                            class="pt-2"
                            :style="{
                                color:
                                    dateToday -
                                        formatDate3(
                                            item.engineeringDate._seconds
                                        ) ==
                                    0
                                        ? '#fc5e03'
                                        : dateToday -
                                              formatDate3(
                                                  item.engineeringDate._seconds
                                              ) <
                                          0
                                        ? ''
                                        : 'red',
                            }"
                        >
                            {{
                                item.engineeringDate &&
                                item.engineeringDate._seconds
                                    ? formatDate(item.engineeringDate._seconds)
                                    : item.engineeringDate &&
                                      item.engineeringDate.seconds
                                    ? formatDate(item.engineeringDate.seconds)
                                    : item.engineeringDate
                                    ? item.engineeringDate
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.status`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.engineering &&
                                item.preDesign[item.preDesign.length - 1]
                                    ?.rejectedOn
                                    ? 'Rejected'
                                    : item.engineering &&
                                      item.engineering == true &&
                                      item.quote &&
                                      item.quote.status != 'SENT'
                                    ? 'Engineering'
                                    : item.quote.status == 'OPEN'
                                    ? 'Bidding'
                                    : item.quote.status == 'SENT'
                                    ? 'Follow Up'
                                    : item.quote.status == 'APPROVAL'
                                    ? 'Approval'
                                    : ''
                            }}
                        </div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="item">
                            <v-btn
                                icon
                                small
                                depressed
                                class="ml-2"
                                @click.stop="selectFunction(item)"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                small
                                depressed
                                class="ml-2"
                                @click.stop="seeCostsVersions(item)"
                            >
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <!--PRE DESIGN FORM-->
                <v-dialog
                    v-model="preDesignForm"
                    :retain-focus="false"
                    persistent
                    max-width="1200px"
                >
                    <PreDesignForm
                        v-if="preDesignForm"
                        :editPreDesign="editPreDesign"
                        :cost="selectedCost"
                        :loading2="loading2"
                        :companyId="companyId"
                        :originalPreDesign="selectedPreDesign"
                        :rejectedPreDesign="rejectedPreDesign"
                        @close="closePreDesignForm"
                        @savePreDesign="savePreDesignFields"
                        @generatePreDesign="generatePreDesign"
                        @updateCostLocal="updateCost"
                    />
                </v-dialog>
                <!--View Versions-->
                <v-dialog
                    :retain-focus="false"
                    :fullscreen="$vuetify.breakpoint.mobile"
                    v-model="dialogVersions"
                    max-width="900px"
                >
                    <v-card>
                        <v-banner
                            sticky
                            color="white"
                            class="pt-1 pb-1"
                            single-line
                        >
                            <v-card-title class="text-h5 pt-0 pb-0 pl-0">
                                <v-btn
                                    class="mr-3"
                                    depressed
                                    color="primary"
                                    icon
                                    rounded
                                    @click="closeDialogVersions"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>

                                {{ quote.name }}
                            </v-card-title>
                        </v-banner>
                        <v-card-text>
                            <v-row no-gutters class="mt-3">
                                <v-col cols="2" class="d-flex">
                                    <p
                                        class="mr-2 text-subtitle-1 font-weight-black"
                                    >
                                        Scope:
                                    </p>
                                </v-col>
                                <v-col cols="10" class="d-flex  flex-wrap">
                                    <p
                                        style="margin-top: 5px; font-size: medium; font-weight: 500;"
                                    >
                                        {{ costInfo.reference }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="2" class="d-flex">
                                    <p
                                        class="mr-2 text-subtitle-1 font-weight-black"
                                    >
                                        Scope Type:
                                    </p>
                                </v-col>
                                <v-col cols="10" class="d-flex  flex-wrap">
                                    <p
                                        style="margin-top: 5px; font-size: medium; font-weight: 500;"
                                    >
                                        {{ costInfo.category }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text>
                            <v-data-table
                                :headers="headersCosts"
                                class="elevation-0 mt-1"
                                :items="preDesignView"
                                hide-default-footer
                                :mobile-breakpoint="0"
                                :disable-pagination="true"
                            >
                                <template
                                    v-slot:[`item.versionNumber`]="{ item }"
                                >
                                    <p class="my-0 text-capitalize">
                                        {{ item.versionNumber }}
                                    </p>
                                </template>
                                <template v-slot:[`item.createdOn`]="{ item }">
                                    {{ formatDate2(item.createdOn) }}
                                </template>
                                <template v-slot:[`item.engineer`]="{ item }">
                                    {{ getUserName(item.engineer) }}
                                </template>
                                <template v-slot:[`item.rejectedOn`]="{ item }">
                                    {{ formatDate2(item.rejectedOn) }}
                                </template>
                                <template
                                    v-slot:[`item.rejectionReason`]="{ item }"
                                >
                                    {{ item.rejectionReason }}
                                </template>
                                <template v-slot:[`item.name`]="{ item }">
                                    <v-btn
                                        color="transparent"
                                        elevation="0"
                                        small
                                        class="ma-1 text-capitalize text--darken-2  pa-2"
                                    >
                                        <span
                                            @click.stop="
                                                openFile(
                                                    item.name ? item.name : ''
                                                )
                                            "
                                            style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                                            >{{
                                                item.versionNumber
                                                    ? `V${item.versionNumber}`
                                                    : ''
                                            }}</span
                                        >
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import _ from 'lodash'
import { saveFile } from '@/services/storage/saveFile.js'
import { openFile } from '@/services/storage/openFile.js'
export default {
    name: 'Estimating',
    components: {
        PreDesignForm: () =>
            import('@/components/Engineering/PreDesignForm.vue'),
    },
    data() {
        return {
            height: 0,
            headers: [
                {
                    text: 'Index',
                    value: 'index',
                    align: 'center',
                    sortable: false,
                    width: 95,
                },
                {
                    text: 'Quotes',
                    value: 'quote.name',
                    align: 'center',
                    sortable: false,
                    width: 95,
                },
                {
                    text: 'Scopes',
                    value: 'reference',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Scope Type',
                    value: 'category',
                    align: 'center',
                    width: 125,
                    sortable: false,
                },
                {
                    text: 'Invitation Date',
                    value: 'quote.invitationDate',
                    align: 'center',
                    width: 105,
                    sortable: false,
                },
                {
                    text: 'Due Date',
                    value: 'quote.requestDate',
                    align: 'center',
                    width: 110,
                    sortable: false,
                },
                {
                    text: 'Promise Date',
                    value: 'quote.promiseDate',
                    align: 'center',
                    width: 150,
                    sortable: false,
                },
                {
                    text: 'Client',
                    value: 'quote.clientId',
                    align: 'center',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'Users',
                    value: 'quote.userId',
                    align: 'center',
                    width: 120,
                    sortable: false,
                },
                {
                    text: 'Status',
                    value: 'quote.status',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Eng Due Date',
                    value: 'engineeringDate',
                    align: 'center',
                    sortable: false,
                    width: 120,
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    sortable: false,
                    width: 110,
                    align: 'center',
                },
            ],
            headersCosts: [
                {
                    text: 'VERSION',
                    value: 'versionNumber',
                    class: 'grey lighten-3',
                    width: 10,
                },
                {
                    text: 'DATE SENT',
                    value: 'createdOn',
                    class: 'grey lighten-3',
                    sortable: false,
                    width: 30,
                },
                {
                    text: 'ENGINEER',
                    value: 'engineer',
                    class: 'grey lighten-3',
                    sortable: false,
                    width: 30,
                },
                {
                    text: 'DATE REJECTED',
                    value: 'rejectedOn',
                    class: 'grey lighten-3',
                    sortable: false,
                    width: 30,
                },
                {
                    text: 'REASON FOR REJECTION',
                    value: 'rejectionReason',
                    class: 'grey lighten-3',
                    sortable: false,
                    width: 200,
                },
                {
                    text: 'FILE',
                    value: 'name',
                    class: 'grey lighten-3',
                    align: 'left',
                    sortable: false,
                    width: 10,
                },
            ],
            orderedCosts: [],
            quote: {},
            loading: false,
            loading2: false,
            quotes: [],
            quotesForUser: [],
            selectedQuote: {},
            costs: [],
            quotesToFilter: '',
            referenceToFilter: '',
            categoryToFilter: '',
            promiseDateToFilter: '',
            invitationDateToFilter: '',
            engineeringDateToFilter: '',
            clientToFilter: '',
            accountManagerToFilter: '',
            usersToFilter: [],
            estimatorToFilter: '',
            statusToFilter: '',
            requestDateToFilter: '',
            dialogCost: false,
            dialogVersions: false,
            costInfo: {},
            preDesignView: [],
            originalCost: {},
            editCost: {},
            preDesignDialog: false,
            selectedCost: {},
            settings: [],
            startDate: '',
            endDate: '',
            users: [],
            quotesArray: [],
            quotesUsersId: [],
            quotesUsers: [],
            colorString: '',
            rules: {
                required: v => !!v || 'Required',
                number: v => v >= 0 || 'Invalid number',
            },
            engineeringDate: '',
            engineeringDateDialog: false,
            user: {},
            readOnly: false,
            engineeringCostPermission: false,
            resourceId: undefined,
            dato1: '',
            dato2: '',
            companyId: '',
            valid: false,
            alertDialog: false,
            preDesign: {},
            createPreDesign: false,
            preDesignForm: false,
            editPreDesign: false,
            selectedPreDesign: {},
            dateToday:
                (Date.now() - new Date().getTimezoneOffset() * 60000) / 1000,
            rejectedPreDesign: false,
        }
    },
    updated() {
        this.onResize()
    },
    computed: {
        filters() {
            let conditions = []

            if (this.quotesToFilter) {
                conditions.push(this.filterForQuotes)
            }
            if (this.referenceToFilter) {
                conditions.push(this.filterForReference)
            }
            if (this.categoryToFilter) {
                conditions.push(this.filterForCategory)
            }
            if (this.promiseDateToFilter) {
                conditions.push(this.filterForPromiseDate)
            }
            if (this.invitationDateToFilter) {
                conditions.push(this.filterForInvitationDate)
            }
            if (this.requestDateToFilter) {
                conditions.push(this.filterForRequestDate)
            }
            if (this.engineeringDateToFilter) {
                conditions.push(this.filterForEngineeringDate)
            }
            if (this.clientToFilter) {
                conditions.push(this.filterForClient)
            }
            if (this.usersToFilter) {
                conditions.push(this.filterForUsers)
            }
            if (this.estimatorToFilter) {
                conditions.push(this.filterForEstimator)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }
            conditions.push(this.filterForCosts)

            if (conditions.length > 0) {
                return this.costs.filter(quote => {
                    return conditions.every(condition => {
                        return condition(quote)
                    })
                })
            }
            this.sort()
            return this.costs
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            const {
                data: { clients },
            } = await API.getClients()
            this.clients = clients
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.companyId = this.user.companyId
            this.engineeringCostPermission = user.permissions.includes(
                'engineeringCost'
            )
            await this.loadData('OPEN,APPROVAL')
            this.dateToday = this.formatDate3(this.dateToday)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async openFile(fileName) {
            try {
                if (
                    this.companyId &&
                    this.quote.id &&
                    (this.costInfo.id || this.costInfo.document_id)
                ) {
                    await openFile(
                        fileName,
                        `${this.companyId}/quotes/${this.quote.id}/costs/${this
                            .costInfo.id ||
                            this.costInfo.document_id}/preDesigns`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filterForQuotes(item) {
            if (item && item.quote && this.quotesToFilter.length > 0) {
                return this.quotesToFilter.some(
                    quote => quote == item.quote.name
                )
            } else {
                return true
            }
        },
        filterForUsers(item) {
            if (item && item.quote && this.usersToFilter.length > 0) {
                let validation = this.usersToFilter.every(field =>
                    [item.quote.userId, item.quote.estimatorId].includes(field)
                )
                return validation
            } else {
                return true
            }
        },
        filterForReference(item) {
            if (item.reference) {
                return item.reference
                    .toLowerCase()
                    .includes(this.referenceToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCategory(item) {
            if (item.category) {
                return item.category
                    .toLowerCase()
                    .includes(this.categoryToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForPromiseDate(item) {
            if (item.quote.promiseDate && item.quote.promiseDate._seconds) {
                const date = this.formatDate(
                    item.quote.promiseDate._seconds
                        ? item.quote.promiseDate._seconds
                        : item.quote.promiseDate.seconds
                        ? item.quote.promiseDate.seconds
                        : 0
                )
                return date.includes(this.promiseDateToFilter)
            } else {
                return false
            }
        },
        filterForInvitationDate(item) {
            if (
                item.quote.invitationDate &&
                item.quote.invitationDate._seconds
            ) {
                const date = this.formatDate(
                    item.quote.invitationDate._seconds
                        ? item.quote.invitationDate._seconds
                        : item.quote.invitationDate.seconds
                        ? item.quote.invitationDate.seconds
                        : 0
                )
                return date.includes(this.invitationDateToFilter)
            } else {
                return false
            }
        },
        filterForClient(item) {
            if (item.quote.clientId) {
                return this.getClientName(item.quote.clientId)
                    .toLowerCase()
                    .includes(this.clientToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForAccountManager(item) {
            if (item.quote.userId) {
                return this.getUserName(item.quote.userId)
                    .toLowerCase()
                    .includes(this.accountManagerToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCosts(item) {
            if (item.engineering) {
                return item.engineering
            } else {
                return false
            }
        },
        filterForEstimator(item) {
            if (item.quote.estimatorId) {
                return this.getUserName(item.quote.estimatorId)
                    .toLowerCase()
                    .includes(this.estimatorToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForStatus(item) {
            if (item.engineering) {
                return (
                    item.engineering &&
                    'engineering'
                        .toLowerCase()
                        .includes(this.statusToFilter.toLowerCase())
                )
            } else if (item.quote.status) {
                return this.setStatus(item.quote.status)
                    .toLowerCase()
                    .includes(this.statusToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForRequestDate(item) {
            if (item.quote.requestDate) {
                const date = this.formatDate(
                    item.quote.requestDate._seconds
                        ? item.quote.requestDate._seconds
                        : item.quote.requestDate.seconds
                        ? item.quote.requestDate.seconds
                        : 0
                )
                return date.includes(this.requestDateToFilter)
            } else {
                return false
            }
        },
        filterForEngineeringDate(item) {
            if (item.engineeringDate && item.engineeringDate._seconds) {
                const date = this.formatDate(
                    item.engineeringDate._seconds
                        ? item.engineeringDate._seconds
                        : item.engineeringDate.seconds
                        ? item.engineeringDate.seconds
                        : 0
                )
                return date.includes(this.engineeringDateToFilter)
            } else {
                return false
            }
        },
        setStatus(status) {
            if (status == 'OPEN') {
                return 'Bidding'
            }
            if (status == 'SENT') {
                return 'Follow Up'
            }
            if (status == 'APPROVAL') {
                return 'Approved'
            }
        },
        async loadData(status) {
            try {
                this.loading = true
                this.menu = false
                //set initial dates
                this.endDate = new Date()
                this.endDate = this.endDate.toISOString().split('T')[0]

                let {
                    data: { quotes },
                } = await API.getSimpleQuotesByStatus(status)
                quotes.forEach(quote => {
                    const indexExchanges = this.settings.findIndex(
                        u => u.name === 'Exchange'
                    )
                    const { exchanges, globalCurrency } = this.settings[
                        indexExchanges
                    ]
                    quote.costExchanges = {
                        exchanges,
                        globalCurrency,
                    }
                    if (quote.userId) {
                        quote.mainContact = this.users.find(
                            user => quote.userId == user.id
                        )
                    }
                    if (quote.estimatorId && !quote.estimatorInfo) {
                        quote.estimatorInfo = this.users.find(
                            user => quote.estimatorId == user.id
                        )
                    }
                    quote.createdOn = quote.createdOn ? quote.createdOn : null
                })

                quotes.sort(
                    (a, b) => a.createdOn._seconds - b.createdOn._seconds
                )
                let index = quotes.findIndex(q => q.createdOn)
                this.startDate = this.formatDate(
                    quotes[index].createdOn._seconds ||
                        quotes[index].createdOn.seconds
                )
                let {
                    data: { costs },
                } = await API.getLiteCostsBigQ(this.startDate, this.endDate)
                costs.forEach(cost => {
                    cost.quoteId = cost.document_name.split('/')[8]
                    cost.costDocumentId = cost.document_name.split('/')[10]
                    cost.quote = quotes.find(quote => quote.id == cost.quoteId)

                    if (cost.quote) {
                        cost.quote.lastUpdate = cost.quote.updatedOn
                        cost.createdOn = cost.createdOn
                            ? this.formatDate(cost.createdOn._seconds)
                            : null
                    }
                })
                costs = costs.filter(cost => cost.quote !== undefined)
                costs.sort(
                    (a, b) =>
                        b.quote.updatedOn._seconds - a.quote.updatedOn._seconds
                )
                //Only leave quotes with costs
                quotes.forEach(quote => {
                    let exist = costs.find(cost => cost.quoteId == quote.id)
                    if (exist) {
                        this.quotesArray.push({
                            name: quote.name,
                            id: quote.id,
                        })
                        if (quote.estimatorId) {
                            this.quotesUsersId.push(quote.estimatorId)
                        }
                        if (quote.userId) {
                            this.quotesUsersId.push(quote.userId)
                        }
                    } else {
                        // quotes = quotes.filter(q => q.id != quote.id)
                    }
                })
                this.quotesUsersId = [...new Set(this.quotesUsersId)]
                this.quotesUsersId.forEach(user =>
                    this.quotesUsers.push({
                        id: user,
                        name: this.getUserName(user),
                    })
                )
                if (this.quotes.length > 0)
                    this.quotes = this.quotes.concat(quotes)
                else this.quotes = quotes
                if (this.costs.length > 0) this.costs = this.costs.concat(costs)
                else this.costs = costs
                this.costs = this.costs.filter(
                    cost => cost.engineer == this.user.id && cost.engineering
                )
                const setQuotes = new Set()
                this.costs.forEach(cost => {
                    if (cost.quote) {
                        setQuotes.add(cost.quote.name)
                    }
                })
                this.quotesForUser = Array.from(setQuotes)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.sort()
                this.loading = false
            }
        },
        sort() {
            this.costs.sort(
                (a, b) =>
                    a.engineeringDate._seconds - b.engineeringDate._seconds
            )
        },
        seeCostsVersions(item) {
            this.costInfo = _.cloneDeep(item)
            this.quote = _.cloneDeep(item.quote)
            if (this.costInfo.preDesign) {
                this.preDesignView = _.cloneDeep(
                    this.costInfo.preDesign.filter(file => file.generatedOn)
                )
            }
            this.dialogVersions = true
        },
        closeDialogVersions() {
            this.dialogVersions = false
            this.costInfo = {}
            this.quote = {}
        },
        async savePreDesignFields(preDesign) {
            try {
                this.loading2 = true
                const costDocumentId = preDesign.costDocumentId
                const indexPredesign = preDesign.indexPredesign
                delete preDesign.costDocumentId
                delete preDesign.indexPredesign
                const indexQuote = this.costs.findIndex(
                    doc => doc.costDocumentId === costDocumentId
                )
                if (indexQuote >= 0) {
                    if (indexPredesign != undefined) {
                        this.costs[indexQuote].preDesign[
                            indexPredesign
                        ] = preDesign
                    } else {
                        this.costs[indexQuote].preDesign.push({ preDesign })
                    }
                }
                let createdOn = new Date().toISOString()

                const cost = await API.addPreDesign({
                    quoteId: this.selectedCost.quoteId,
                    costId: this.selectedCost.document_id,

                    preDesign: {
                        ...preDesign,
                        createdOn,
                    },
                })

                if (cost) {
                    this.updateCost(cost)
                    this.selectedCost = _.cloneDeep({
                        ...this.selectedCost,
                        ...cost,
                    })
                    this.selectedPreDesign = preDesign
                }
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
                this.loading = false
            }
        },
        async generatePreDesign(selectedCost, preDesign, pdfFile, time) {
            try {
                this.loading = true
                const generatedOn = new Date().toISOString()
                const cost = await API.addPreDesign({
                    quoteId: selectedCost.quoteId,
                    costId: selectedCost.document_id,
                    preDesign: {
                        generatedOn,
                        versionNumber: preDesign.versionNumber,
                        id: preDesign.id,
                        engineering: false,
                    },
                })
                await API.updateEngineeringHours(this.user.id, {
                    engineeringHours: {
                        [selectedCost.category]: {
                            hours: Number(time),
                            count: preDesign.versionNumber == 1 ? 1 : 0,
                        },
                    },
                })
                selectedCost = { ...selectedCost, ...cost }
                selectedCost.engineering = false
                await saveFile(
                    pdfFile,
                    `${this.companyId}/quotes/${selectedCost.quoteId}/costs/${selectedCost.document_id}/preDesigns`
                )
                if (selectedCost) {
                    this.updateCost(selectedCost)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatDate(seconds = undefined) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        formatDate3(seconds) {
            return moment.unix(seconds).format('YYYYMMDD')
        },

        formatDate2(date) {
            if (!date) return ''

            if (typeof date === 'object' && (date._seconds || date.seconds)) {
                return moment
                    .unix(date._seconds || date.seconds)
                    .format('YYYY-MM-DD')
            } else if (
                typeof date === 'string' &&
                moment(date, moment.ISO_8601, true).isValid()
            ) {
                return moment(date).format('YYYY-MM-DD')
            } else {
                console.warn('Invalid date format:', date)
                return ''
            }
        },

        getUserName(id) {
            if (this.users.length > 0 && id) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
            this.heightPopUp = window.innerHeight - 62
        },
        updateCost(cost) {
            if (cost) {
                if (
                    cost.engineeringDate &&
                    typeof cost.engineeringDate == 'string'
                ) {
                    cost.engineeringDate = cost.engineeringDate.substring(0, 10)
                }
                let index = this.costs.findIndex(c => {
                    return (
                        (c.document_id == cost.costId ||
                            c.document_id == cost.document_id ||
                            c.document_id == cost.id) &&
                        c.quoteId == cost.quoteId
                    )
                })
                if (index !== -1) {
                    this.costs.splice(index, 1, {
                        ...this.costs[index],
                        ...cost,
                    })
                }
            }
        },
        selectFunction(item) {
            const index = item.preDesign
                ? item.preDesign.findIndex(
                      doc => doc.versionNumber && !doc.rejectedOn
                  )
                : -1
            const status =
                item.engineering &&
                item.preDesign[item.preDesign.length - 1]?.rejectedOn
                    ? 1
                    : 0
            this.selectedCost = _.cloneDeep(item)
            if (status === 1) {
                this.selectedCost = _.cloneDeep(item)
                this.editPreDesign = true
                this.rejectedPreDesign = true
                this.selectedPreDesign = _.cloneDeep(
                    item.preDesign[item.preDesign.length - 1]
                )
                delete this.selectedPreDesign.createdOn
                delete this.selectedPreDesign.engineer
                delete this.selectedPreDesign.generatedOn
                delete this.selectedPreDesign.id
                delete this.selectedPreDesign.name
                delete this.selectedPreDesign.rejectedOn
                delete this.selectedPreDesign.rejectedBy
                delete this.selectedPreDesign.rejectionReason
                delete this.selectedPreDesign.versionNumber
                const versionNumber =
                    item.preDesign.length > 0 ? item.preDesign.length + 1 : 1
                this.selectedPreDesign.versionNumber = versionNumber
                this.selectedPreDesign.costDocumentId = item.costDocumentId
            } else if (index >= 0 && status === 0) {
                this.rejectedPreDesign = false
                this.selectedCost = _.cloneDeep(item)
                this.editPreDesign = true
                this.selectedPreDesign = _.cloneDeep(item.preDesign[index])
                this.selectedPreDesign.costDocumentId = item.costDocumentId
                this.selectedPreDesign.indexPredesign = index
            } else {
                this.editPreDesign = false
                this.rejectedPreDesign = false
            }
            this.preDesignForm = true
        },
        openEditPreDesignForm(item) {
            this.selectedCost = _.cloneDeep(item)
            this.editPreDesign = true
            this.selectedPreDesign = _.cloneDeep(
                item.preDesign ? item.preDesign[item.preDesign.length - 1] : {}
            )
            this.preDesignForm = true
        },

        closePreDesignForm() {
            this.selectedPreDesign = {}
            this.selectedCost = {}
            this.preDesignForm = false
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
/* .top {
    background-color: #eeeeee;
} */
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.clickable-row {
    cursor: pointer;
}
</style>
